import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { orange, cyan, teal } from '@mui/material/colors'
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';

import AboutUsPage from './AboutUsPage';
import LandingPage from './LandingPage';
import PlanEditPage from './PlanEditPage';
import PlanListPage from './PlanListPage';
import PlanViewPage from './PlanViewPage';
import SignUpPage from './SignUpPage';
import PrivacyPolicyPage from './PrivacyPolicyPage';
import TermsOfServicePage from './TermsOfServicePage';

export default function App() {
  let theme = createTheme({
    typography: {
      fontSize: 16,
      fontFamily: 'Inter, sans-serif',

      h1: { fontSize: 32, fontWeight: 700 },
      h2: { fontSize: 28, fontWeight: 700 },
      h3: { fontSize: 24, fontWeight: 500 },
      h4: { fontSize: 22, fontWeight: 500 },
      h5: { fontSize: 20, fontWeight: 500 },
      h6: { fontSize: 18 },
    },
    palette: {
      primary: teal,
      secondary: orange,
      info: cyan,
    },
  });

  document.body.style.backgroundColor = orange[50];

  const router = createBrowserRouter([
    {
      path: '/',
      element: <LandingPage />
    },
    {
      path: '/palvelusta',
      element: <AboutUsPage />
    },
    {
      path: '/kirjaudu',
      element: <SignUpPage />
    },
    {
      path: '/kayttoehdot',
      element: <TermsOfServicePage />
    },
    {
      path: '/tietosuoja',
      element: <PrivacyPolicyPage />
    },
    {
      path: '/suunnitelma/:id',
      element: <PlanViewPage />
    },
    {
      path: '/suunnitelmat',
      element: <PlanListPage />
    },
    {
      path: '/suunnitelmat/:id',
      element: <PlanEditPage />
    },
  ]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={router}>
        </RouterProvider>
      </ThemeProvider>
    </>
  );
}