import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { store } from './store';
import * as api from './api'

export default function useAuthentication({ disableRedirection } = { disableRedirection: false }) {
  const navigate = useNavigate();
  const hasPlans = store.useState((s) => s.plans !== null);

  const [searchParams] = useSearchParams();
  const urlHash = searchParams.get('hash');
  const localStorageHash = localStorage.getItem('hash');

  const hash = urlHash || localStorageHash;

  useEffect(() => {
    if (!hash) {
      store.update((s) => {
        s.isLoggedIn = false;
      });

      if (!disableRedirection) {
        navigate('/');
      }

      return;
    }

    if (hasPlans) {
      store.update((s) => {
        s.isLoggedIn = true;
      });

      return;
    }

    const execute = async () => {
      let data;
      try {
        data = await api.getAllUserData(hash);
      } catch (e) {
        console.error(e);
        data = { error: 'verkkoyhteysvirhe' };
      }

      if (data.error) {
        store.update((s) => {
          s.isLoggedIn = false;
        });

        if (!disableRedirection) {
          // TODO: Toast
          navigate('/');
        }

        return;
      }

      store.update((s) => {
        s.isLoggedIn = true;
        s.authenticationHash = hash;
        s.email = data.email;
        s.plans = data.plans;
      });

      localStorage.setItem('hash', hash);
    };

    execute();
  }, [hash, hasPlans, disableRedirection, navigate]);
}