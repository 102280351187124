import { Box, Link, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import TaskIcon from '@mui/icons-material/Task';

export default function TextLogo({ fontSize, withLink }) {
  fontSize = fontSize || '32px';

  const logo = (
    <Stack direction="row" spacing={2} alignItems="flex-start">
      <TaskIcon sx={{ fontSize }} />

      <Typography component="span" fontWeight="600" fontSize={fontSize} lineHeight="1em">
        omavalvonta<wbr />suunnitelma<wbr /><Box component="span" fontWeight="400">.com</Box>
      </Typography>
    </Stack>
  );

  if (withLink) {
    return (
      <Link component={RouterLink} to="/" sx={{ color: 'inherit', textDecoration: 'none' }}>
        {logo}
      </Link>
    );
  } else {
    return logo;
  }
}