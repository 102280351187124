import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import TaskIcon from '@mui/icons-material/Task';

import Navigation from './Navigation';
import DefaultContainer from './DefaultContainer';
import * as api from '../api';
import { store } from '../store';
import useAuthentication from '../useAuthentication';
import useScrollToTop from '../useScrollToTop';

export default function SignUpPage() {
  useAuthentication({ disableRedirection: true });
  const navigate = useNavigate();
  const isLoggedIn = store.useState((s) => s.isLoggedIn);
  const [isLoading, setIsLoading] = useState(false);
  const [serverMessage, setServerMessage] = useState(null);

  useScrollToTop();

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/suunnitelmat');
    }
  }, [isLoggedIn, navigate])

  const onSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const email = data.get('email');

    setIsLoading(true);
    let response;
    try {
      response = await api.sendLoginLink(email);
    } catch (e) {
      console.error(e);
      response = { error: 'verkkoyhteysvirhe' };
    }
    setIsLoading(false);

    if (response.error) {
      setServerMessage(
        <Typography>Linkin lähettäminen ei onnistunut: {response.error}. Paina <Link onClick={() => setServerMessage(null)}>tästä</Link> palataksesi.</Typography>
      );
    } else {
      setServerMessage(
        <Typography>Linkin pitäisi nyt olla lähtenyt osoitteeseen "{email}". Tarkista sähköpostisi ja seuraa viestissä mainittua linkkiä. Jos sähköposti ei ole saapunut vielä viiden minuutin kuluttua, <Link onClick={() => setServerMessage(null)}>yritä uudestaan</Link>.</Typography>
      );
    }
  };

  return (
    <>
      <Navigation goBackTo="/" noLogout />

      <DefaultContainer maxWidth="xs">
        <Stack alignItems="center" spacing={4}>
          <TaskIcon color="secondary" sx={{ fontSize: '128px' }} />

          <Stack alignItems="center" spacing={1}>
            <Typography variant="h2">Uusi käyttäjä?</Typography>

            <Typography variant="subtitle1">Vai hukkuiko kirjautumislinkki?</Typography>
          </Stack>

          {serverMessage !== null
            ? (
              <Card>
                <CardContent>
                  {serverMessage}
                </CardContent>
              </Card>
            )
            : isLoading
            ? (
              <Stack direction="row" justifyContent="center">
                <CircularProgress />
              </Stack>
            )
            : (
              <Stack component="form" spacing={2} width={1} onSubmit={onSubmit}>
                <TextField
                  autoComplete="email"
                  autoFocus
                  fullWidth
                  label="Sähköpostiosoite"
                  margin="normal"
                  name="email"
                  required
                />

                <Button fullWidth type="submit" variant="contained">
                  Lähetä linkki
                </Button>
              </Stack>
            )}

          <Typography variant="body2" alignSelf="flex-start">
            Rekisteröitymällä palveluun hyväksyt <Link component={RouterLink} to="/kayttoehdot">käyttöehdot</Link>.
          </Typography>

          <Typography variant="body2" alignSelf="flex-start">
            Jos sisäänkirjautumisessa on ongelmia, ota yhteyttä: <Link component="a" href="mailto:info@omavalvontasuunnitelma.com">info@omavalvontasuunnitelma.com</Link>
          </Typography>
        </Stack>
      </DefaultContainer>
    </>
  );
}