import { Button, IconButton, Link, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { resetStore, store } from "../store";

export default function Navigation({ goBackTo, noLogout }) {
  const navigate = useNavigate();
  const email = store.useState(s => s.email);

  const goBack = () => {
    navigate(goBackTo || -1);
  };

  const logout = () => {
    localStorage.removeItem('hash');
    resetStore();
    navigate('/');
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={4} sx={{ m: { xs: 2, sm: 4 }, minHeight: '56px' }}> {/* FIXME: minHeight is arbitrary */}
      {goBackTo !== null && (
        <Link onClick={goBack} sx={{ marginRight: 'auto!important' }}> {/* FIXME: Ugly hack to move this to the left side; fiddling around with this breaks ellipsis in email */}
          <IconButton color="primary">
            <ArrowBackIcon fontSize="large" />
          </IconButton>
        </Link>
      )}

      {noLogout !== true && email && (
        <>
          <Typography overflow="hidden" textOverflow="ellipsis">{email}</Typography>

          <Link onClick={logout} flexShrink={0}>
            <Button variant="outlined" startIcon={<LogoutIcon />}>
              <Typography>Ulos</Typography>
            </Button>
          </Link>
        </>
      )}
    </Stack>
  );
}