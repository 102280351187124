import { Container, Link, Stack, Typography } from '@mui/material';
import { orange } from '@mui/material/colors';
import { Link as RouterLink } from 'react-router-dom';

import { store } from '../store';

function Footer() {
  return (
    <Stack spacing={{ xs: 4, sm: 1 }} bgcolor={orange[100]} sx={{ px: 2, py: 4, marginTop: 'auto' }}>
      <Stack direction="row" justifyContent="center" spacing={2}>
        <Typography variant="body2">
          Palvelun tuottaa Alstand Oy: <Link component="a" href="mailto:info@omavalvontasuunnitelma.com">info@omavalvontasuunnitelma.com</Link>
        </Typography>
      </Stack>

      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="center" spacing={2}>
        <Typography variant="body2">
          <Link component={RouterLink} to="/kayttoehdot">Käyttöehdot</Link>
        </Typography>

        <Typography variant="body2">
          <Link component={RouterLink} to="/tietosuoja">Tietosuojaseloste</Link>
        </Typography>

        <Typography variant="body2">
          <Link component={RouterLink} to="/palvelusta">Tietoa palvelusta</Link> 
        </Typography>
      </Stack>
    </Stack>
  );
}

export default function DefaultContainer({ children, ...otherProps }) {
  const isLoggedIn = store.useState((s) => s.isLoggedIn);

  const minHeight = isLoggedIn // FIXME: Fugly
    ? {
      xs: `calc(100vh - (56px + (16px * 2)))`,
      sm: `calc(100vh - (56px + (32px * 2)))`,
    } : {
      xs: `calc(100vh)`,
    };

  return (
    <Stack minHeight={minHeight}>
      <Container maxWidth="md" sx={{ mt: { xs: 4, sm: 8 }, mb: { xs: 8, sm: 16 }}} {...otherProps}>
        {children}
      </Container>

      <Footer />
    </Stack>
  )
}