export function createPlanUrl(id) {
  const { protocol, hostname, port } = window.location;
  return `${protocol}//${hostname}${port !== '' ? ':' + port : ''}/suunnitelma/${id}`;
}

export function createShortPlanUrl(id) {
  return createPlanUrl(id).slice(0, -28);
}

export function assert(test, message) {
  if (!test) {
    throw new Error(`Assertion failed: ${message}`);
  }
}

export function timestampToDate(ts) {
  return new Date(ts).toLocaleDateString('fi-FI', { year: 'numeric', month: 'numeric', day: 'numeric' });
}