import { assert } from './util';

export const categories = [
  {
    header: 'Palveluntuottajaa, palveluyksikköä ja toimintaa koskevat tiedot',
    indexNumber: '1',
    questions: [
      {
        id: 'palveluntuottajan_perustiedot', // 4.1.1
        indexNumber: '1.1',
        header: 'Palveluntuottajan perustiedot',
        subquestions: [
          {
            id: 'palveluntuottajan_perustiedot.nimi',
            header: 'Nimi',
            isRequired: true,
          },
          {
            id: 'palveluntuottajan_perustiedot.ytunnus',
            header: 'Yritys- ja yhteisötunnus',
            isRequired: true,
          },
          {
            id: 'palveluntuottajan_perustiedot.yhteystiedot',
            header: 'Yhteystiedot',
            isRequired: true,
          },
          {
            id: 'palveluntuottajan_perustiedot.muut_palveluntuottajat',
            header: 'Yhteiseen palveluyksikköön kuuluvien palveluntuottajien nimet, Y-tunnukset ja yhteystiedot',
          },
        ]
      },
      {
        id: 'palveluyksikon_perustiedot', // 4.1.2
        indexNumber: '1.2',
        header: 'Palveluyksikön perustiedot',
        subquestions: [
          {
            id: 'palveluyksikon_perustiedot.nimi',
            header: 'Nimi',
            isRequired: true,
          },
          {
            id: 'palveluyksikon_perustiedot.yhteystiedot',
            header: 'Yhteystiedot',
            isRequired: true,
          },
          {
            id: 'palveluyksikon_perustiedot.vastuuhenkilot',
            header: 'Vastuuhenkilöiden/palvelualojen nimet ja yhteystiedot',
          },
          {
            id: 'palveluyksikon_perustiedot.valtuutettu_palveluntuottaja',
            header: 'Yhteisen palveluyksikön valtuutetun palveluntuottajan nimi ja yhteystiedot',
          },
        ]
      },
      {
        id: 'palvelut', // 4.1.3
        indexNumber: '1.3',
        header: 'Palvelut, toiminta-ajatus ja toimintaperiaatteet',
        subquestions: [
          {
            id: 'palvelut.katetut_palvelut',
            header: 'Mitkä ovat palveluyksikön ja omavalvontasuunnitelman kattamat palvelut?',
          },
          {
            id: 'palvelut.toimintayksikot',
            header: 'Missä toimintayksiköissä ja miten palveluja tuotetaan?',
            // extraInfo: 'Esim. läsnäpalvelu, etäpalvelu'
          },
          {
            id: 'palvelut.toiminta_ajatus',
            header: 'Mikä on palveluyksikön toiminta-ajatus ja mitkä ovat sen toimintaperiaatteet?',
            // extraInfo: 'Kuten asiakas- ja potilasmäärät ja -ryhmät, tuotetaanko palveluja ostopalveluna tai alihankintana toiselle palveluntuottajalle tai -järjestäjälle, sopimuksen perusteella suoraan asiakkaalle tai potilaalle taikka hankitaanko palveluja toiselta palveluntuottajalta',
          },
          {
            id: 'palvelut.hyvinvointialueet',
            header: 'Minkä hyvinvointialueiden alueilla palveluja tuotetaan?',
          },
        ]
      }
    ]
  },
  {
    header: 'Asiakas- ja potilasturvallisuus',
    indexNumber: '2',
    questions: [
      {
        id: 'laadulliset_edellytykset', // 4.2.1
        indexNumber: '2.1',
        header: 'Palveluiden laadulliset edellytykset',
        bullets: [
          'Mitkä ovat palveluntuottajan palveluyksikön palveluille asettamat laatuvaatimukset ja laadunhallinnan toteuttamistavat?',
            // extraInfo: 'Mukaan lukien palvelunarvioinnissa käytössä olevat laadunhallinnan työkalut ja mittarit, sekä miten palveluiden laatu varmistetaan',
          'Miten palveluyksikön palveluiden asiakas- ja potilasturvallisuus varmistetaan konkreettisesti?',
        ]
      },
      {
        id: 'vastuu_laadusta', // 4.2.2
        indexNumber: '2.2',
        header: 'Vastuu palvelujen laadusta',
        bullets: [
          'Mitkä ovat palveluyksikön omavalvonnan vastuusuhteet ja johtamisjärjestelmä?',
            // extraInfo: 'Ota huomioon palvelualakohtaisen lainsäädännön mukaiset sosiaali- ja terveydenhuollon toimintayksikköjen toiminnasta vastaavat henkilöt ja heidän tehtävänsä.',
          'Miten palveluyksikön vastuuhenkilö tai palvelualojen vastuuhenkilöt käytännössä johtavat vastuulleen kuuluvaa palvelutoimintaa ja valvovat, että palvelut täyttävät niille säädetyt edellytykset koko sen ajan, kun palveluja toteutetaan?',
            // extraInfo: 'Valvontalain 10 §:n 4 momentin mukaisesti',
        ]
      },
      {
        id: 'asema_ja_oikeudet', // 4.2.3
        indexNumber: '2.3',
        header: 'Asiakkaan ja potilaan asema ja oikeudet',
        bullets: [
          'Miten varmistetaan asiakkaan ja potilaan pääsy palveluihin ja hoitoon?',
          'Miten varmistetaan asiakkaan ja potilaan tiedonsaantioikeus ja osallisuus, jotta hänellä on tosiasiallinen mahdollisuus osallistua palvelujaan koskevaan suunnitteluun, päätöksentekoon ja toteuttamiseen?',
          'Miten varmistetaan asiakkaan ja potilaan asiallinen kohtelu ja miten menetellään, jos epäasiallista kohtelua havaitaan?',
          'Miten varmistetaan asiakkaan ja potilaan informointi hänen käytettävissään olevista oikeusturvakeinoista?',
          'Miten varmistetaan asiakkaiden ja potilaiden itsemääräämisoikeuden toteutuminen, ja mitkä ovat asiakkaan ja potilaan itsemääräämisoikeuden rajoittamista koskevat periaatteet ja rajoitusten konkreettiset menettelytavat?',
            // extraInfo: 'Omavalvontasuunnitelmasta tulee ilmetä palveluyksikön asiakkaan ja potilaan itsemääräämisoikeuden vahvistamiseksi laaditut suunnitelmat ja ohjeet, mukaan lukien niistä vastaava henkilö.',
          'Mitkä ovat asiakkaan ja potilaan lakisääteisten palvelua koskevien suunnitelmien laadinnan ja päivittämisen menettelytavat?',
            // extraInfo: 'Esimerkiksi asiakas-, palvelu- tai hoitosuunnitelmat',
          'Miten varmistetaan, että palveluyksikön henkilökunta toimii asiakkaalle ja potilaalle laadittavien suunnitelmien mukaisesti, ja miten suunnitelmien toteutumista ja päivittämistä seurataan?',
          'Palveluyksikön sosiaali- ja potilasasiavastaavien tehtävät ja yhteystiedot',
        ]
      },
      {
        id: 'muistutukset', // 4.2.4
        indexNumber: '2.4',
        header: 'Muistutusten käsittely',
        bullets: [
          'Kuka vastaa muistutusten käsittelystä?',
          'Mitkä ovat muistutusten käsittelyä koskevat menettelytavat ja miten varmistetaan, että muistutukset käsitellään asianmukaisesti?',
          'Miten muistutuksissa ilmi tulleet epäkohdat tai puutteet otetaan huomioon toiminnassa ja sen kehittämisessä?',
        ],
      },
      {
        id: 'henkilosto', // 4.2.5
        indexNumber: '2.5',
        header: 'Henkilöstö',
        bullets: [
          'Palveluyksikön henkilöstön määrä, rakenne ja sijaisten käytön periaatteet.',
            // extraInfo: 'Kuvauksesta on ilmettävä kuinka paljon vuokrattua työvoimaa tai toiselta palveluntuottajalta alihankittua työvoimaa käytetään täydentämään palveluntuottajan omaa henkilöstöä.',
          'Miten varmistetaan sosiaali- ja terveydenhuollon ammattihenkilöiden ammattioikeudet?',
          'Miten varmistetaan, että palvelun tuottamiseen osallistuvalla henkilöstöllä on työtehtäviinsä asianmukainen koulutus, riittävä osaaminen ja ammattitaito sekä kielitaito?',
          'Miten huolehditaan henkilöstön riittävästä perehdyttämisestä ja ammattitaidon ylläpitämisestä sekä siitä, että henkilöstö osallistuu riittävästi ammatilliseen täydennyskoulutukseen?',
          'Miten laillistetun sosiaali- tai terveydenhuollon ammattihenkilön tehtävissä tilapäisesti toimivien opiskelijoiden oikeus työskennellä varmistetaan sekä miten opiskelijoiden ohjaus, johto ja valvonta toteutetaan käytännössä?',
          'Miten henkilöstön osaamista ja työskentelyn asianmukaisuutta seurataan toiminnan aikana, ja miten havaittuihin epäkohtiin puututaan?',
          'Miten selvitetään työntekijän rikostausta?',
            // extraInfo: 'Lasten kanssa työskenteleviltä annetun lain (504/2002) perusteella, sekä iäkkäiden ja vammaisten henkilöiden kanssa työskenteleviltä valvontalain 28 §:n perusteella',
        ]
      },
      {
        id: 'riittävyyden_seuranta', // 4.2.6
        indexNumber: '2.6',
        header: 'Asiakas- ja potilastyöhön osallistuvan henkilöstön riittävyyden seuranta',
        bullets: [
          'Miten palveluyksikön tai palvelualojen vastuuhenkilöt varmistavat kaikissa tilanteissa, että sosiaali- ja terveyspalvelujen antamiseen on palvelujen tarpeeseen ja asiakkaiden ja potilaiden määrään nähden riittävä henkilöstö?',
          'Mitkä ovat konkreettiset toimintamallit henkilöstön riittävyyden varmistamiseksi ja potilaiden ja asiakkaiden palvelutarpeeseen vastaamiseksi sekä niitä tilanteita varten, kun henkilöstöä ei ole riittävästi?',
            // extraInfo: 'Henkilöstön riittävyyden seurannan ja varmistamisen on katettava kaikki palveluyksikössä ja sen lukuun tuotettavat palvelut.',
        ]
      },
      {
        id: 'yhteistyo_ja_koordinointi', // 4.2.7
        indexNumber: '2.7',
        header: 'Monialainen yhteistyö ja palvelun koordinointi',
        bullets: [
          'Miten yhteistyö ja tiedonkulku asiakkaan ja potilaan palvelukokonaisuuteen kuuluvien muiden sosiaali- ja terveydenhuollon palvelunjärjestäjien ja -tuottajien kanssa toteutetaan?',
          'Miten varmistetaan asiakkaalle tai potilaalle annettaviin palveluihin liittyvä yhteistyö palveluntuottajan muiden palveluyksikköjen kanssa?',
        ]
      },
      {
        id: 'toimitilat_ja_valineet', // 4.2.8
        indexNumber: '2.8',
        header: 'Toimitilat ja välineet',
        bullets: [
          'Kuvaa toiminnassa käytettävät toimitilat, mukaan lukien toimintaympäristö, ja välineet sekä niiden turvallisuus, turvallinen käyttö ja soveltuvuus tarkoitukseensa',
          'Kuvaa toimitiloille tehdyt tarkastukset sekä myönnetyt viranomaishyväksynnät ja luvat (esim. kliinisen mikrobiologian ja Säteilyturvakeskuksen myöntämät luvat) päivämäärineen.',
            // extraInfo: 'Kuvauksessa on otettava huomioon tarkastuksissa ja hyväksynnöissä toimitilojen omavalvonnan kannalta todetut keskeiset havainnot.',
          'Mitkä ovat toimitilojen ja välineiden käyttöön liittyvät riskit, mukaan lukien niihin liittyvä asiakkaan ja potilaan yksityisyyden suoja, ja niiden hallintakeinot?',
          'Mitkä ovat toimitilojen ylläpitoa, huoltoa sekä epäkohtailmoituksia ja tiedonkulkua koskevat menettelyt?',
            // extraInfo: 'Toimitilojen asianmukaisuutta arvioitaessa on otettava huomioon myös muut mahdolliset palvelualakohtaisesta lainsäädännöstä tulevat vaatimukset.',
          'Mitkä ovat palvelutoimintaan käytettävän kiinteistön pitkäjänteistä ylläpitoa koskevat toimintamallit, resurssit ja suunnitelmat?',
          'Miten varmistetaan, että palveluyksikössä ei ole käytössä epäasianmukaisia tai palveluihin nähden soveltumattomia ja turvallisuusriskin muodostavia välineitä.',
            // extraInfo: 'Kuvauksesta on selvittävä, miten välineiden huolto ja niiden käytön vaatima koulutus on järjestetty.',
        ]
      },
      {
        id: 'laitteet_tietojarjestelmat_ja_teknologia', // 4.2.9
        indexNumber: '2.9',
        header: 'Lääkinnälliset laitteet, tietojärjestelmät ja teknologian käyttö',
        bullets: [
          'Miten varmistetaan lääkinnällisistä laitteista annetun lain (719/2021) mukaisten velvoitteiden noudattaminen?',
          'Miten varmistetaan, että palveluyksikössä käytetään olennaiset vaatimukset täyttäviä asiakastietolain mukaisia tietojärjestelmiä, jotka vastaavat käyttötarkoitukseltaan palveluntuottajan toimintaa ja joiden tiedot löytyvät Valviran tietojärjestelmärekisteristä?',
          'Miten huolehditaan asiakastietolain mukaisten tietojärjestelmien käytön asianmukaisuudesta, henkilöstön kouluttamisesta niiden käyttöön ja jatkuvasta käyttöön liittyvän osaamisen varmistamisesta palveluyksikössä?',
          'Milloin asiakastietolain mukainen tietoturvasuunnitelma on laadittu ja päivitetty sekä kuka palveluyksikössä vastaa sen toteutumisesta?',
          'Miten huolehditaan rekisterinpitäjän oikeuksien ja vastuiden toteutumisesta palveluyksikön toiminnassa, myös ostopalvelu- ja alihankintatilanteissa?',
          'Miten huolehditaan asiakastietolain 90 §:n mukaisesta velvollisuudesta ilmoittaa tietojärjestelmän olennaisten vaatimusten poikkeamista ja tietoverkkoihin kohdistuvista tietoturvallisuuden häiriöistä sekä tarpeenmukaisista omavalvonnallisista toimista koko poikkeaman ja häiriötilanteen keston ajan?',
          'Miten käytännössä varmistetaan palveluissa hyödynnettävän teknologian soveltuvuus, asianmukaisuus ja turvallisuus tarjottaviin palveluihin nähden sekä henkilöstön käyttöopastus?',
            // extraInfo: 'Kuvauksesta on ilmettävä, miten palveluyksikössä on varauduttu teknologian vikatilanteisiin ja pitkiin huoltoviiveisiin.',
          'Miten varmistetaan asiakkaan ja potilaan yksilölliset tarpeet ja itsemääräämisoikeuden toteutuminen hyödynnettäessä teknologiaa palvelujen tuottamisessa?',
        ]
      },
      {
        id: 'laakehoitosuunnitelma', // 4.2.10
        indexNumber: '2.10',
        header: 'Lääkehoitosuunnitelma',
        bullets: [
          'Milloin palveluyksikön lääkehoitosuunnitelma on laadittu tai milloin se on päivitetty ja kuka vastaa sen laatimisesta?',
          'Kuka vastaa palveluyksikön lääkehoidon asianmukaisuudesta ja lääkehoitosuunnitelman toteutumisesta ja seurannasta palveluyksikössä?',
          'Sosiaalihuollon palveluasumisen yksikön (toimintayksikön) rajatun lääkevaraston vastuuhenkilön nimi',
        ]
      },
      {
        id: 'tietojen_kasittely_ja_tietosuoja', // 4.2.11
        indexNumber: '2.11',
        header: 'Asiakas- ja potilastietojen käsittely ja tietosuoja',
        bullets: [
          'Palveluyksikön asiakastietojen käsittelystä ja siihen liittyvästä ohjeistuksesta vastaava johtaja',
            // extraInfo: 'Asiakastietolain 7 §:n mukaisesti',
          'Palveluntuottajan tietosuojavastaavan nimi ja yhteystiedot',
        ]
      },
      {
        id: 'palaute', // 4.2.12
        indexNumber: '2.12',
        header: 'Säännöllisesti kerättävän ja muun palautteen huomioiminen',
        bullets: [
          'Miten palveluyksikön palveluja saavilta asiakkailta ja potilailta, heidän omaisiltaan ja läheisiltään sekä palveluyksikön henkilöstöltä kerätään säännöllisesti palautetta?',
          'Miten säännöllisesti kerättyä ja muuten saatua palautetta hyödynnetään omavalvonnan ja toiminnan laadun kehittämisessä?',
        ]
      }
    ]
  },
  {
    header: 'Omavalvonnan riskienhallinta',
    indexNumber: '3',
    questions: [
      {
        id: 'riskienhallinnan_vastuut_tunnistaminen_ja_arvioiminen', // 4.3.1
        indexNumber: '3.1',
        header: 'Palveluyksikön riskienhallinnan vastuut, riskien tunnistaminen ja arvioiminen',
        bullets: [
          'Kuka vastaa palveluyksikön toimintojen riskienhallinnasta ja miten riskienhallinta on organisoitu?',
          'Miten palveluyksikön toiminnan riskejä tunnistetaan ja mitkä ovat asiakas- ja potilasturvallisuuden kannalta palveluyksikön toiminnan keskeiset riskit?',
          'Miten riskienhallinnassa tunnistettujen riskien suuruus ja vaikutus palvelutoimintaan arvioidaan?',
        ]
      },
      {
        id: 'epakohtien_ja_puutteiden_kasittely', // 4.3.2
        indexNumber: '3.2',
        header: 'Riskienhallinnan keinot ja toiminnassa ilmenevien epäkohtien ja puutteiden käsittely',
        bullets: [
          'Millaisin käytännön toimin palveluntuottaja ennaltaehkäisee ja hallitsee palveluyksikön toimintaan ja asiakas- ja potilasturvallisuuteen kohdistuvia riskejä?',
          'Miten riskienhallintakeinojen toimivuus ja riittävyys varmistetaan?',
          'Miten varmistetaan, että omavalvonnassa ilmeneviin epäkohtiin ja puutteisiin puututaan tilanteen edellyttämällä tavalla?',
          'Vaaratapahtumien ilmoitus- ja oppimismenettely?',
          'Palveluntuottajan ja henkilöstön valvontalain 29 §:n mukaisen ilmoitusvelvollisuuden ja -oikeuden toteuttamista sekä muita mahdollisia lakisääteisiä ilmoitusvelvollisuuksia koskevat menettelyohjeet',
          'Miten henkilöstölle tiedotetaan ilmoitusvelvollisuuksista ja -oikeuksista ja niiden käytöstä?',
          'Miten käsitellään ja otetaan palveluyksikön riskienhallinnassa huomioon valvovien viranomaisten selvityspyynnöt, ohjaus ja päätökset?',
        ]
      },
      {
        id: 'seuranta_raportointi_ja_osaamisen_varmistaminen', // 4.3.3
        indexNumber: '3.3',
        header: 'Riskienhallinnan seuranta, raportointi ja osaamisen varmistaminen',
        bullets: [
          'Miten riskienhallinnan toimivuutta seurataan ja arvioidaan?',
          'Miten hygieniaohjeiden ja infektiotorjunnan toteutumista seurataan?',
          'Vaara- ja haittatapahtumien raportointikäytännöt',
          'Miten henkilöstön riskienhallinnan osaaminen varmistetaan?',
        ]
      },
      {
        id: 'ostopalvelut_ja_alihankinta', // 4.3.4
        indexNumber: '3.4',
        header: 'Ostopalvelut ja alihankinta',
        bullets: [
          'Miten palveluntuottaja käytännössä varmistaa palveluyksikön omavalvonnan riskienhallinnan toteutumisen ostopalvelu- ja alihankintatilanteissa?',
        ]
      },
      {
        id: 'valmius_ja_jatkuvuudenhallinta', // 4.3.5
        indexNumber: '3.5',
        header: 'Valmius- ja jatkuvuudenhallinta',
        bullets: [
          'Kuka vastaa palveluyksikön valmius- ja jatkuvuudenhallinnasta ja valmius- ja jatkuvuussuunnitelmasta?',
        ]
      }
    ]
  },
  {
    header: 'Omavalvontasuunnitelman toimeenpano, julkaiseminen, toteutumisen seuranta ja päivittäminen',
    indexNumber: '4',
    questions: [
      {
        id: 'toimeenpano', // 5.1
        indexNumber: '4.1',
        header: 'Toimeenpano',
        bullets: [
          'Miten varmistetaan henkilöstön omavalvonnan osaaminen ja sitoutuminen jatkuvaan omavalvontasuunnitelman mukaiseen toimintaan?',
          'Miten omavalvontasuunnitelman ja siihen tehtävien päivitysten asianmukainen toteutuminen varmistetaan palveluyksikön päivittäisessä toiminnassa?',
        ]
      },
      {
        id: 'julkaiseminen', // 5.2
        indexNumber: '4.2',
        header: 'Julkaiseminen, toteutumisen seuranta ja päivittäminen',
        bullets: [
          'Millä julkisuutta edistävällä tavalla omavalvontasuunnitelma julkaistaan ja miten se on julkisesti nähtävänä palveluyksikössä?',
          'Miten varmistetaan, että omavalvontasuunnitelma pidetään ajan tasalla ja siihen tehtävät päivitykset julkaistaan viiveettä?',
          'Miten varmistetaan, että omavalvontasuunnitelman toteutumista seurataan ja seurannassa havaitut puutteellisuudet korjataan miten varmistetaan, että seurannasta tehdään selvitys ja sen perusteella tehtävät muutokset julkaistaan vähintään neljän kuukauden välein?',
        ]
      }
    ]
  }
];

function validate() {
  assert(Array.isArray(categories));

  for (const category of categories) {
    assert(typeof category.header === 'string');
    assert(typeof category.indexNumber === 'string');
    assert(Array.isArray(category.questions));

    for (const question of category.questions) {
      assert(typeof question === 'object');
      assert(typeof question.id === 'string');
      assert(typeof question.indexNumber === 'string');
      assert(typeof question.header === 'string');

      if ('isRequired' in question) {
        assert(typeof question.isRequired === 'boolean');
      }

      if ('bullets' in question) {
        assert(Array.isArray(question.bullets));

        for (const bullet of question.bullets) {
          assert(typeof bullet === 'string');
        }
      } else if ('subquestions' in question) {
        assert(Array.isArray(question.subquestions));

        for (const subquestion of question.subquestions) {
          assert(typeof subquestion === 'object');
          assert(typeof subquestion.id === 'string');
          assert(typeof subquestion.header === 'string');

          if ('isRequired' in subquestion) {
            assert(typeof subquestion.isRequired === 'boolean');
          }
        }
      }
    }
  }
}

validate();