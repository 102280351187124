import { Box, Grid, Link, Paper, Stack, Typography } from '@mui/material';

import DefaultContainer from './DefaultContainer';
import Navigation from './Navigation';
import useScrollToTop from '../useScrollToTop';
import paallikkoImage from '../assets/paallikko.jpg';

export default function PrivacyPolicyPage() {
  useScrollToTop();

  return (
    <>
      <Navigation to="-1" />

      <DefaultContainer maxWidth="sm">
        <Stack spacing={4}>
          <Typography variant="h2">Tietoa palvelusta</Typography>

          <Paper sx={{ p: 4, hyphens: 'auto' }}>
            <Stack spacing={4}>
              <Grid container justifyContent="center">
                <Box component="img" src={paallikkoImage} sx={{ borderRadius: 1, width: '50%' }} />
              </Grid>

              <Stack spacing={4}>
                <Typography>
                  <strong>Omavalvontasuunnitelma.com</strong> syntyi tarpeesta ratkaista oma ongelma. Oli löydettävä keino pitää yllä yksityisen terveydenhuollon palveluntuottajan omavalvontasuunnitelmia ja pitää ne julkisesti saatavilla. Samalla heräsi ajatus tarjota tätä palvelua myös muille. Toivottavasti tästä on apua!
                </Typography>

                <Typography>
                  Palvelun taustalla on lääkäri-DI Taavi Saviauk, joka on mm. perustanut potilassiirtojärjestelmä Uoman kehittäneen yhtiön.
                </Typography>

                <Typography variant="h4" component="h3">Yhteystiedot</Typography>

                <Typography>
                  Alstand Oy (2520801-9)<br />
                  <Link component="a" href="mailto:info@omavalvontasuunnitelma.com">info@omavalvontasuunnitelma.com</Link>
                </Typography>
              </Stack>
            </Stack>
          </Paper>
        </Stack>
      </DefaultContainer>
    </>
  )
}