import { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useNavigate } from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import Navigation from './Navigation';
import DefaultContainer from './DefaultContainer';
import useAuthentication from '../useAuthentication';
import { store } from "../store";
import { createShortPlanUrl } from "../util";
import * as api from "../api";
import calculatePrice from "../calculatePrice";
import TextLogo from "./TextLogo";

// eslint-disable-next-line
function Price({ plans }) {
  const price = calculatePrice(plans);

  return (
    <Typography>Tämänhetkinen kuukausiveloituksesi on <Typography component="span" fontWeight="bold">{price}</Typography>.</Typography>
  );
}

function PlanCreationDialog({ isOpen, close }) {
  const authenticationHash = store.useState((s) => s.authenticationHash);
  const sortedPlans = store.useState((s) => Object.values(s.plans || {}).sort((a, b) => b.name - a.name));
  const navigate = useNavigate();

  const createPlan = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const name = formData.get('name');
    const copyFromId = formData.get('copyFromId');

    let plan;
    try {
      plan = await api.createNewPlan(authenticationHash, name, copyFromId);
    } catch (e) {
      console.error(e);
      plan = { error: 'verkkoyhteysvirhe' }
    }

    if (plan.error) {
      // TODO: Toast
      return;
    }

    store.update((s) => {
      s.plans[plan.id] = plan;
    });

    navigate(`/suunnitelmat/${plan.id}`);
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth
      onClose={close}
      PaperProps={{ component: 'form', onSubmit: createPlan }}>
      <DialogTitle>Luo uusi suunnitelma</DialogTitle>

      <DialogContent>
        <Stack spacing={2} marginY={2}>
          <TextField
            autoFocus
            required
            margin="dense"
            name="name"
            label="Toimipisteen nimi"
            fullWidth
          />

          {sortedPlans.length >= 1 && (
            <FormControl fullWidth>
              <InputLabel id="selectId">Esitäytä olemassaolevalta suunnitelmalta</InputLabel>
              <Select defaultValue={""} labelId="selectId" name="copyFromId" label="Esitäytä olemassaolevalta suunnitelmalta">
                <MenuItem value={""}>&lt;älä esitäytä&gt;</MenuItem>
                {sortedPlans.map((plan) => (
                  <MenuItem key={plan.id} value={plan.id}>{plan.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button sx={{ color: grey[500] }} onClick={close}>Peru</Button>
        <Button color="success" type="submit">Luo</Button>
      </DialogActions>
    </Dialog>
  );
}

function PlanDeletionDialog({ deletedPlanId, close }) {
  const authenticationHash = store.useState((s) => s.authenticationHash);
  const deletedPlanName = store.useState((s) => s.plans?.[deletedPlanId]?.name);

  const deletePlan = async () => {
    let response;
    try {
      response = await api.deletePlan(authenticationHash, deletedPlanId);
    } catch (e) {
      console.error(e);
      return;
    }

    if (response.error) {
      console.error(response.error);
      return;
    }

    store.update((s) => {
      delete s.plans[deletedPlanId];
    });

    close();
  };

  return (
    <Dialog open={deletedPlanId !== null} onClose={close}>
      <DialogContent>
        <DialogContentText>
          Haluatko varmasti poistaa suunnitelman toimipisteeltä <strong>{deletedPlanName}</strong>?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={close}>Älä poista</Button>
        <Button color="warning" onClick={deletePlan}>Poista</Button>
      </DialogActions>
    </Dialog>
  );
}

function PlanRenamingDialog({ renamedPlanId, close }) {
  const authenticationHash = store.useState((s) => s.authenticationHash);
  const renamedPlanName = store.useState((s) => s.plans?.[renamedPlanId]?.name);

  const renamePlan = async (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    const name = data.get('name');

    let response;
    try {
      response = await api.changePlanName(authenticationHash, renamedPlanId, name);
    } catch (e) {
      console.error(e);
      return;
    }

    if (response.error) {
      console.error(response.error);
      return;
    }

    store.update((s) => {
      s.plans[renamedPlanId].name = name;
    });

    close();
  };

  return (
    <Dialog
      open={renamedPlanId !== null}
      fullWidth
      onClose={close}
      PaperProps={{ component: 'form', onSubmit: renamePlan }}>
      <DialogTitle>Muuta nimeä</DialogTitle>

      <DialogContent>
        <Stack spacing={2} marginY={2}>
          <TextField
            autoFocus
            required
            margin="dense"
            name="name"
            label="Toimipisteen nimi"
            fullWidth
            defaultValue={renamedPlanName}
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button sx={{ color: grey[500] }} onClick={close}>Peru</Button>
        <Button color="success" type="submit">Tallenna</Button>
      </DialogActions>
    </Dialog>
  )
}

function PlanRow({ id, startRenaming, startDeleting }) {
  const navigate = useNavigate();
  const authenticationHash = store.useState((s) => s.authenticationHash);
  const { name, isActive } = store.useState((s) => s.plans?.[id]);

  const planUrl = createShortPlanUrl(id);

  const toggleActivation = async () => {
    const changedActiveStatus = !isActive;

    let response;
    try {
      response = await api.changePlanActiveStatus(authenticationHash, id, changedActiveStatus);
    } catch (e) {
      console.error(e);
      return;
    }

    if (response.error) {
      console.error(response.error);
      return;
    }

    store.update((s) => {
      s.plans[id].isActive = changedActiveStatus;
    })
  };

  return (
    <TableRow key={id} sx={{ opacity: isActive ? 1 : 0.5 }}>
      <TableCell width={0.01}>
        <Checkbox checked={isActive} onClick={toggleActivation} />
      </TableCell>

      <TableCell>
        <Button variant="text" sx={{ lineHeight: 'inherit', textAlign: 'left', textTransform: 'none' }} onClick={() => startRenaming(id)}>
          {name}
        </Button>
      </TableCell>

      <TableCell width={0.01} sx={{ paddingRight: 0 }}>
        <IconButton color="primary" onClick={() => navigator.clipboard.writeText(planUrl)}>
          <ContentCopyIcon fontSize="small" />
        </IconButton>
      </TableCell>

      <TableCell>
        <Link component="a" href={planUrl}>{planUrl}</Link>
      </TableCell>

      <TableCell width={0.01}>
        <Button onClick={() => navigate(`/suunnitelmat/${id}`)}>Muokkaa</Button>
      </TableCell>

      <TableCell width={0.01}>
        <Button color="warning" onClick={() => startDeleting(id)}>Poista</Button>
      </TableCell>
    </TableRow>
  );
}

function PlanList() {
  const [planCreationOpen, setPlanCreationOpen] = useState(false);
  const [deletedPlanId, setDeletedPlanId] = useState(null);
  const [renamedPlanId, setRenamedPlanId] = useState(null);

  const sortedPlanIds = store.useState((s) =>
    Object.values(s.plans || {})
      .sort((a, b) => b.modifiedAt - a.modifiedAt)
      .map(({ id }) => id)
  );

  return (
    <Stack spacing={4}>
      <PlanCreationDialog isOpen={planCreationOpen} close={() => setPlanCreationOpen(false)} />
      <PlanDeletionDialog deletedPlanId={deletedPlanId} close={() => setDeletedPlanId(null)} />
      <PlanRenamingDialog renamedPlanId={renamedPlanId} close={() => setRenamedPlanId(null)} />

      <Button variant="contained" onClick={() => setPlanCreationOpen(true)} sx={{ width: 'fit-content' }}>Luo uusi suunnitelma</Button>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Aktiivinen?</TableCell>
              <TableCell>Toimipiste</TableCell>
              <TableCell colSpan={2}>Linkki</TableCell>
              <TableCell colSpan={2}></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {sortedPlanIds.map((id) => (
              <PlanRow
                key={id}
                id={id}
                startDeleting={setDeletedPlanId}
                startRenaming={setRenamedPlanId}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}

function EmptyPlanList() {
  const [isPlanCreationOpen, setIsPlanCreationOpen] = useState(false);

  return (
    <Paper>
      <PlanCreationDialog isOpen={isPlanCreationOpen} close={() => setIsPlanCreationOpen(false)} />

      <Stack spacing={4} p={4}>
        <Typography>Suunnitelmalista on tyhjä.</Typography>
        <Button variant="contained" onClick={() => setIsPlanCreationOpen(true)} sx={{ width: 'fit-content' }}>Luo uusi suunnitelma</Button>
      </Stack>
    </Paper>
  );
}

export default function PlanListPage() {
  useAuthentication();
  const plansN = store.useState((s) => s.plans ? Object.values(s.plans).length : null);

  useEffect(() => {
    store.update((s) => {
      s.draftAnswers = {}; // Make sure no draft answers linger around to potentially mess up UX
    })
  }, []);

  return (
    <>
      <Navigation goBackTo={null} />

      <DefaultContainer maxWidth="lg">
        <Stack spacing={8}>
          <TextLogo />

          {/*<Typography variant="h2">Suunnitelmasi</Typography>*/}

          {plansN === null ? (
            <CircularProgress />
          ) : plansN === 0 ? (
            <EmptyPlanList />
          ) : (
            <>
              {/* While in demo mode; <Price plans={plans} />*/}
              <PlanList />
            </>
          )}
        </Stack>
      </DefaultContainer>
    </>
  );
}