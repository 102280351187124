import { Paper, Stack, Typography } from '@mui/material';

import DefaultContainer from './DefaultContainer';
import Navigation from './Navigation';
import useScrollToTop from '../useScrollToTop';

export default function PrivacyPolicyPage() {
  useScrollToTop();

  return (
    <>
      <Navigation to="-1" />

      <DefaultContainer>
        <Stack spacing={4}>
          <Typography variant="h2">Tieto&shy;suoja&shy;seloste</Typography>

          <Typography variant="body2">Päivitetty 26.6.2024</Typography>

          <Paper sx={{ p: 4, hyphens: 'auto' }}>
            <Stack spacing={4}>
              <Typography variant="h3">1. Rekisterinpitäjä</Typography>

              <ul>
                <li>Nimi: Alstand Oy</li>
                <li>Osoite: Alankotie 10 A 1, 00730 Helsinki</li>
                <li>Y-tunnus: 2520801-9</li>
                <li>Yhteystiedot: info@omavalvontasuunnitelma.com</li>
              </ul>

              <Typography variant="h3">2. Yhteyshenkilö tietosuoja-asioissa</Typography>

              <ul>
                <li>Nimi: Taavi Saviauk</li>
                <li>Yhteystiedot: info@omavalvontasuunnitelma.com</li>
              </ul>

              <Typography variant="h3">3. Rekisterin nimi</Typography>

              <ul>
                <li>Omavalvontasuunnitelma.com:n asiakasrekisteri</li>
              </ul>

              <Typography variant="h3">4. Henkilötietojen käsittelyn tarkoitus</Typography>

              <ul>
                <li>Henkilötietoja käsitellään seuraaviin tarkoituksiin:</li>

                <ul>
                  <li>Asiakassuhteen hoitaminen ja kehittäminen</li>
                  <li>Markkinointi ja viestintä</li>
                  <li>Laskutus ja maksuliikenne</li>
                  <li>Lakisääteisten velvoitteiden täyttäminen</li>
                </ul>
              </ul>

              <Typography variant="h3">5. Rekisterin tietosisältö</Typography>

              <ul>
                <li>Rekisteri voi sisältää seuraavia tietoja:</li>

                <ul>
                  <li>Nimi</li>
                  <li>Yhteystiedot (osoite, sähköpostiosoite, puhelinnumero)</li>
                  <li>Asiakassuhdetta koskevat tiedot (tilaushistoria, asiakasviestintä)</li>
                  <li>Laskutustiedot</li>
                  <li>Sähköiset tunnistetiedot (IP-osoite, evästetiedot)</li>
                </ul>
              </ul>

              <Typography variant="h3">6. Säännönmukaiset tietolähteet</Typography>

              <ul>
                <li>Henkilötiedot saadaan pääsääntöisesti seuraavista lähteistä:</li>

                <ul>
                  <li>Asiakas itse (esim. tilauksen yhteydessä)</li>
                  <li>Julkiset rekisterit</li>
                  <li>Yrityksen verkkosivujen kautta kerätyt tiedot</li>
                </ul>
              </ul>

              <Typography variant="h3">7. Tietojen säännönmukaiset luovutukset ja tietojen siirto EU:n tai ETA-alueen ulkopuolelle</Typography>

              <ul>
                <li>Henkilötietoja voidaan luovuttaa:</li>

                <ul>
                  <li>Viranomaisille lain edellyttämissä tapauksissa</li>
                  <li>Yrityksen kumppaneille ja alihankkijoille, jotka osallistuvat palveluiden tuottamiseen</li>
                </ul>

                <li>Tietoja ei siirretä EU:n tai ETA-alueen ulkopuolelle ilman asianmukaisia suojatoimia.</li>
              </ul>

              <Typography variant="h3">8. Evästeiden (cookies) käyttö</Typography>

              <ul>
                <li>Käytämme evästeitä (cookies) parantaaksemme verkkosivujemme käyttökokemusta.</li>
                <li>Evästeet ovat pieniä tekstitiedostoja, jotka tallennetaan käyttäjän laitteelle.</li>
                <li>Evästeiden avulla keräämme tietoja muun muassa seuraavista:</li>

                <ul>
                  <li>Verkkosivujen käyttö (esim. vierailtujen sivujen määrä, istunnon kesto)</li>
                  <li>Käyttäjän tekemät valinnat ja asetukset</li>
                  <li>Analytiikka ja tilastot (esim. Google Analytics)</li>
                  <li>Markkinointi ja mainosten kohdentaminen</li>
                </ul>

                <li>Käyttäjä voi hallita ja poistaa evästeitä selaimensa asetuksista. Evästeiden käytön estäminen saattaa kuitenkin vaikuttaa verkkosivujen toiminnallisuuteen.</li>
              </ul>

              <Typography variant="h3">9. Rekisterin suojauksen periaatteet</Typography>

              <ul>
                <li>Sähköinen aineisto on suojattu teknisin keinoin (esim. salaus, palomuurit)</li>
                <li>Pääsy tietoihin on rajattu vain niille henkilöille, jotka tarvitsevat tietoja työtehtäviensä hoitamiseksi</li>
              </ul>

              <Typography variant="h3">10. Tarkastusoikeus ja oikeus vaatia tiedon korjaamista</Typography>

              <ul>
                <li>Rekisteröidyllä on oikeus tarkastaa häntä koskevat tiedot ja vaatia virheellisten tietojen korjaamista.</li>
                <li>Tarkastus- ja korjauspyynnöt tulee toimittaa kirjallisesti rekisterinpitäjälle.</li>
              </ul>

              <Typography variant="h3">11. Muut rekisteröidyn oikeudet</Typography>

              <ul>
                <li>Rekisteröidyllä on oikeus:</li>

                <ul>
                  <li>Vastustaa tietojensa käsittelyä suoramarkkinointia varten</li>
                  <li>Pyytää tietojensa poistamista ("oikeus tulla unohdetuksi")</li>
                  <li>Rajoittaa tietojensa käsittelyä tietyissä tilanteissa</li>
                  <li>Siirtää tiedot järjestelmästä toiseen</li>
                </ul>

                <li>Oikeuksien käyttämistä koskevat pyynnöt tulee toimittaa kirjallisesti rekisterinpitäjälle.</li>
              </ul>

              <Typography variant="h3">12. Tietosuojaselosteen muuttaminen</Typography>

              <ul>
                <li>Rekisterinpitäjä pidättää oikeuden muuttaa tätä tietosuojaselostetta. Muutoksista ilmoitetaan asianmukaisella tavalla, esimerkiksi yrityksen verkkosivuilla.</li>
              </ul>
            </Stack>
          </Paper>
        </Stack>
      </DefaultContainer>
    </>
  )
}