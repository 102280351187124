import { useEffect, useState } from "react";
import { Alert, Button, CircularProgress, Link, Paper, Stack, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import DefaultContainer from './DefaultContainer';
import Navigation from './Navigation';
import * as api from '../api';
import { store } from '../store';
import { categories } from "../questions";
import useAuthentication from "../useAuthentication";
import { createShortPlanUrl, timestampToDate } from "../util";
import TextLogo from "./TextLogo";

function Answer({ question, answer }) {
  return (
    <Stack key={question.id} spacing={2} paddingLeft={{ xs: 2, md: 4 }}>
      <Stack direction="row" spacing={2}>
        {'indexNumber' in question && <Typography component="h3" variant="h5">{question.indexNumber}.</Typography>}
        <Typography component="h3" variant="h5">{question.header}</Typography>
      </Stack>
      <Typography sx={{ hyphens: 'auto', whiteSpace: 'pre-wrap' }}>{answer}</Typography>
    </Stack>
  );
}

function Category({ category, answers }) {
  const renderedQuestions = category.questions.map((question) => {
    if ('subquestions' in question) {
      const renderedAnswers =
        question.subquestions
          .filter(({ id }) => typeof answers[id] === 'string')
          .map((subquestion) => (
            <Answer
              key={subquestion.id}
              answer={answers[subquestion.id]}
              question={subquestion}
            />
          ))

      if (renderedAnswers.length === 0) {
        return null;
      }

      return (
        <Stack spacing={4} paddingLeft={{ xs: 1, md: 4 }} paddingTop={{ xs: 2, md: 4 }}>
          <Stack direction="row" spacing={2}>
            <Typography variant="h4">{question.indexNumber}</Typography>
            <Typography variant="h4">{question.header}</Typography>
          </Stack>

          {renderedAnswers}
        </Stack>
      );
    } else {
      const answer = answers[question.id];

      if (!answer) {
        return null;
      }

      return (
        <Answer key={question.id} answer={answer} question={question} />
      );
    }
  });

  const noRenderedQuestions = renderedQuestions.filter(x => x).length === 0;
  if (noRenderedQuestions) {
    return null;
  }

  return (
    <Stack spacing={4}>
      <Stack direction="row" spacing={2} paddingTop={{ xs: 2, md: 4 }}>
        <Typography variant="h3">{category.indexNumber}.</Typography>
        <Typography variant="h3">{category.header}</Typography>
      </Stack>

      {renderedQuestions}
    </Stack>
  );
}

function FormattedPlan({ plan }) {
  const noAnswers = Object.keys(plan.answers).length === 0;
  const rendered = noAnswers
    ? (
      <Typography variant="body2">Suunnitelman luonti on vielä kesken.</Typography>
    )
    : categories.map((category) => (
      <Category key={category.indexNumber} category={category} answers={plan.answers} />
    ));

  return (
    <Paper sx={{ opacity: plan.isActive ? 1 : 0.6 }}>
      <Stack spacing={4} sx={{ p: { xs: 2, sm: 4, md: 8 }}}>
        <Typography variant="h2">Oma&shy;valvonta&shy;suunnitelma</Typography>

        <Stack spacing={{ xs: 4, md: 8 }}>
          {rendered}
        </Stack>
      </Stack>
    </Paper>
  );
}

export default function PlanViewPage() {
  useAuthentication({ disableRedirection: true });
  const navigate = useNavigate();
  const { id } = useParams();
  const isLoggedIn = store.useState((s) => s.isLoggedIn);
  const [plan, setPlan] = useState(null);
  const [isMissing, setIsMissing] = useState(false);

  useEffect(() => {
    const execute = async () => {
      let plan;
      try {
        plan = await api.getPlan(id);
      } catch (e) {
        console.error(e);
        plan = null;
      }

      if (!plan) {
        setIsMissing(true);
      } else {
        setPlan(plan);
      }
    };

    execute();
  }, [id]);

  useEffect(() => {
    if (isMissing) {
      // TODO: Toast
      navigate('/suunnitelmat');
    }
  }, [isMissing, navigate]);

  useEffect(() => {
    if (isLoggedIn === false && plan && !plan.isActive) {
      navigate('/');
    }
  }, [isLoggedIn, plan, navigate]);

  if (!plan) {
    return (
      <DefaultContainer>
        <Stack direction="row" justifyContent="center">
          <CircularProgress />
        </Stack>
      </DefaultContainer>
    );
  }

  const copyLink = (url) => {
    navigator.clipboard.writeText(url);
  };

  return (
    <>
      {isLoggedIn && <Navigation goBackTo="/suunnitelmat" />}

      <DefaultContainer>
        <Stack spacing={8} sx={{ marginTop: isLoggedIn ? 0 : 4 }}>
          <TextLogo withLink />

          {isLoggedIn && (
            <Alert severity="info" color="warning" variant="outlined">
              {plan.isActive ? (
                <Stack spacing={1}>
                  <Typography variant="body2">
                    Tämä suunnitelma näkyy kaikille, joille jaat linkin:<br />
                  </Typography>

                  <Stack spacing={2}>
                    <Link component="a" variant="body2">{createShortPlanUrl(plan.id)}</Link>

                    <Button
                      variant="outlined"
                      size="small"
                      startIcon={<ContentCopyIcon fontSize="small" />}
                      onClick={() => copyLink(createShortPlanUrl(plan.id))}
                      sx={{ lineHeight: '1.2rem', width: 'fit-content' }}>
                      Kopioi linkki
                    </Button>
                  </Stack>
                </Stack>
              ) : (
                <Typography variant="body2">Tämä suunnitelma ei ole käytössä, joten se ei näy muille kuin sinulle.</Typography>
              )}
            </Alert>
          )}

          <Stack spacing={2}>
            <Typography align="right">Viimeksi päivitetty: {timestampToDate(plan.modifiedAt)}</Typography>

            <FormattedPlan plan={plan} />
          </Stack>
        </Stack>
      </DefaultContainer>
    </>
  );
}