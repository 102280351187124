import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Typography
} from '@mui/material';
import { grey, orange, teal } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link as RouterLink } from 'react-router-dom';

import DefaultContainer from './DefaultContainer';
import TextLogo from './TextLogo';

function Hero() {
  return (
    <Stack
      alignItems="center"
      sx={{
        background: `radial-gradient(circle at -50vw -50vh, ${teal[900]}, ${teal[500]})`,
        color: teal[50],
      }}>
      <Stack
        spacing={{ xs: 4, md: 8 }}
        sx={{
          px: { xs: 2, sm: 3, md: 8 },
          py: { xs: 4, sm: 6, md: 12 },
          maxWidth: 1200,
        }}>
        <Stack direction={{ xs: "column", lg: "row" }} alignItems="center" justifyContent="space-between" spacing={4}>
          <TextLogo fontSize={{ xs: '32px', sm: '48px' }} />

          <Link component={RouterLink} to="/kirjaudu">
            <Button variant="contained" color="secondary" size="large">Luo tili</Button>
          </Link>
        </Stack>

        <Stack direction={{ sm: 'column', md: 'row' }} spacing={4}>
          <Stack spacing={2} flex={1}>
            <Typography variant="subtitle1">Laki sosiaali- ja terveydenhuollon valvonnasta velvoittaa yksityisiä palveluntuottajia ylläpitämään omavalvontasuunnitelmaa ja pitämään sen julkisesti näytillä.</Typography>

            <Typography variant="caption">
              <Link component="a" color={teal[100]} target="_blank" href="https://valvira.fi/sosiaali-ja-terveydenhuolto/omavalvontasuunnitelma">https://valvira.fi/sosiaali-ja-terveydenhuolto/omavalvontasuunnitelma</Link><br />
              <Link component="a" color={teal[100]} target="_blank" href="https://valvira.fi/-/omavalvontamaarays-on-julkaistu">https://valvira.fi/-/omavalvontamaarays-on-julkaistu</Link>
            </Typography>
          </Stack>

          <Stack spacing={2} flex={1} sx={{ pt: { xs: 4, sm: 0 }}}> {/* FIXME: pt is hacky */}
            <Typography variant="subtitle1" fontWeight="bold">Tällä palvelulla luot ja ylläpidät helposti palveluyksikkökohtaista omavalvontasuunnitelmaa.</Typography>
            <Typography variant="subtitle1">Palvelu on tarkoitettu yksityisille sosiaali- ja terveydenhuollon palveluntuottajille.</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

function HowDoesItWork() {
  const avatarSx = { backgroundColor: orange[100], color: grey[700] };

  return (
    <Stack spacing={4}>
      <Typography variant="h3">Miten palvelu toimii?</Typography>

      <List>
        <ListItem>
          <ListItemAvatar>
            <Avatar sx={avatarSx}>1</Avatar>
          </ListItemAvatar>
          <ListItemText primary="Rekisteröidy palveluun."/>
        </ListItem>

        <ListItem>
          <ListItemAvatar>
            <Avatar sx={avatarSx}>2</Avatar>
          </ListItemAvatar>
          <ListItemText primary="Luo haluamasi määrä omavalvontasuunnitelmia."/>
        </ListItem>

        <ListItem>
          <ListItemAvatar>
            <Avatar sx={avatarSx}>3</Avatar>
          </ListItemAvatar>
          <ListItemText primary="Saat jokaiselle omavalvontasuunnitelmalle yksilöivän linkin." />
        </ListItem>

        <ListItem>
          <ListItemAvatar>
            <Avatar sx={avatarSx}>4</Avatar>
          </ListItemAvatar>
          <ListItemText primary="Voit lisätä linkin esimerkiksi toimipaikkasi esittelytekstiin." />
        </ListItem>
      </List>
    </Stack>
  );
}

function PricingGridItem({ children, ...props }) {
  return (
    <Grid xs={12} sm={4} sx={{ padding: { xs: 1, sm: 2 }}} {...props}>
      {children}
    </Grid>
  );
}

function Pricing() {
  return (
    <Stack spacing={4}>
      <Typography variant="h3">Hinnoittelu</Typography>

      <Typography color={orange[700]} fontWeight="bold">Palvelussa on 3 kuukauden ilmainen kokeilujakso.</Typography>

      <Typography>Kokeilujakson jälkeen hinnoittelu on kuukausimaksullinen ja määräytyy omavalvontasuunnitelmien määrän mukaan. Usean toimipisteen tilauksesta saat paljousalennuksen.</Typography>

      <Stack spacing={1}>
        <Paper>
          <Stack spacing={1} padding={1}> {/* Grid explodes without this */}
            <Grid container>
              <PricingGridItem fontWeight="500">1&nbsp;toimipiste</PricingGridItem>
              <PricingGridItem>4,90&nbsp;€/kk</PricingGridItem>
              <PricingGridItem>49,00&nbsp;€/vuosi</PricingGridItem>

              <Grid xs={12} paddingY={1}>
                <Divider />
              </Grid>

              <PricingGridItem fontWeight="500">Seuraavat 9&nbsp;toimipistettä</PricingGridItem>
              <PricingGridItem>+2,90&nbsp;€/kk/toimip.</PricingGridItem>
              <PricingGridItem>+29,00&nbsp;€/vuosi/toimip.</PricingGridItem>

              <Grid xs={12} paddingY={1}>
                <Divider />
              </Grid>

              <PricingGridItem fontWeight="500">Loput toimipisteet</PricingGridItem>
              <PricingGridItem>+1,90&nbsp;€/kk/toimip.</PricingGridItem>
              <PricingGridItem>+19,00&nbsp;€/vuosi/toimip.</PricingGridItem>
            </Grid>
          </Stack>
        </Paper>

        <Typography align="right" variant="caption">(hinnat sis. alv 24&nbsp;%)</Typography>
      </Stack>
    </Stack>
  );
}

function FAQ() {
  return (
    <Stack spacing={6}>
      <Typography variant="h3">Usein kysytyt kysymykset</Typography>

      <Stack spacing={2}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Kenelle palvelu on tarkoitettu?
          </AccordionSummary>

          <AccordionDetails>
            Palvelu on tarkoitettu yksityisille sosiaali- ja terveydenhuollon palveluntuottajille, kuten esim. lääkäreille, hammaslääkäreille, fysioterapeuteille, psykologeille jne. Palvelu sopii niin ammatinharjoittajille (eli toiminimellä yrittäville) kuin myös oman yhtiön kautta työskentelevillekin.
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Mistä tiedän, mitä omavalvontasuunnitelmaan pitää täyttää?
          </AccordionSummary>

          <AccordionDetails>
            Suunnitelman sisällöstä päättää jokainen toimija itsenäisesti. Mikäli tarjoat palvelujasi isojen palveluntarjoajien kautta, tarkista heillä olevat mallipohjat, joita voit käyttää omavalvontasuunnitelmasi luonnin pohjana.
          </AccordionDetails>
        </Accordion>
      </Stack>
    </Stack>
  )
}

function FinalCTA() {
  return (
    <Stack spacing={2} justifyContent="center" alignItems="center">
      <Link component={RouterLink} to="/kirjaudu">
        <Button variant="contained" size="large">Aloita käyttö nyt</Button>
      </Link>
    </Stack>
  );
}

export default function Landing() {
  return (
    <>
      <Hero />

      <DefaultContainer>
        <Stack spacing={{ xs: 4, md: 8 }}>
          <HowDoesItWork />
          <Divider />
          <Pricing />
          <Divider />
          <FAQ />
          <Divider />
          <FinalCTA />
        </Stack>
      </DefaultContainer>
    </>
  );
}