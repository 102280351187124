const serverHost =
  window.location.hostname === 'localhost'
    ? 'http://localhost:4000/api/'
    : 'https://omavalvontasuunnitelma.com/api/';

async function call(path, { token, body }) {
  const headers = { 'Content-Type': 'application/json' }
  if (token) {
    headers['Authorization'] = 'Bearer ' + token;
  }

  const response = await fetch(serverHost + path, {
    method: 'POST',
    headers,
    body: body ? JSON.stringify(body) : null
  });

  return response.json();
}

export function getAllUserData(hash) {
  return call('getAllUserData', { token: hash });
}

export function getPlan(id) {
  return call('getPlan', { body: { id } });
}

export function sendLoginLink(email) {
  return call('sendLoginLink', { body: { email }});
}

export function createNewPlan(hash, name, copyFromId) {
  return call('createNewPlan', { token: hash, body: { name, copyFromId }});
}

export function deletePlan(hash, id) {
  return call('deletePlan', { token: hash, body: { id }});
}

export function changePlanActiveStatus(hash, id, isActive) {
  return call('changePlanActiveStatus', { token: hash, body: { id, isActive }});
}

export function changePlanName(hash, id, name) {
  return call('changePlanName', { token: hash, body: { id, name }});
}

export function updatePlanAnswers(hash, id, answers) {
  return call('updatePlanAnswers', { token: hash, body: { id, answers }});
}