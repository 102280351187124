import { Paper, Stack, Typography } from '@mui/material';

import DefaultContainer from './DefaultContainer';
import Navigation from './Navigation';
import useScrollToTop from '../useScrollToTop';

export default function TermsOfServicePage() {
  useScrollToTop();

  return (
    <>
      <Navigation to="-1" />

      <DefaultContainer>
        <Stack spacing={4}>
          <Typography variant="h2">Oma&shy;valvonta&shy;suunnitelma&shy;.comin käyttö&shy;ehdot</Typography>

          <Typography variant="body2">Päivitetty 26.6.2024</Typography>

          <Paper sx={{ p: 4, hyphens: 'auto' }}>
            <Stack spacing={4}>
              <Typography variant="h3">1. Yleistä</Typography>

              <Typography>Nämä käyttöehdot (jäljempänä "Ehdot") koskevat omavalvontasuunnitelma.com-palvelua (jäljempänä "Palvelu"), jossa ylläpidetään yksityisen terveydenhuollon toimijan omavalvontasuunnitelmaa. Palvelun tarjoaa Alstand Oy (jäljempänä "Palveluntarjoaja"). Käyttämällä Palvelua hyväksyt nämä Ehdot ja sitoudut noudattamaan niitä</Typography>

              <Typography variant="h3">2. Palvelun käyttö</Typography>

              <ul>
                <li>Palvelu on tarkoitettu yksityisille sosiaali- ja terveydenhuollon toimijoille, jotka haluavat ylläpitää omavalvontasuunnitelmaansa.</li>
                <li>Asiakas vastaa itse Palveluun syöttämiensä tietojen oikeellisuudesta ja ajantasaisuudesta. Palveluntarjoaja ei vastaa mistään virheistä tai puutteista, jotka johtuvat asiakkaan antamista tiedoista.</li>
                <li>Asiakas sitoutuu käyttämään Palvelua lain ja hyvien tapojen mukaisesti.</li>
              </ul>

              <Typography variant="h3">3. Maksut ja laskutus</Typography>

              <ul>
                <li>Palvelun käyttö on ilmaista ensimmäisen kolmen kuukauden ajan, jonka jälkeen palvelun käyttö on maksullista. Palvelusta veloitetaan kuukausi- tai vuosimaksu etukäteen. Jo perittyä maksua ei palauteta, vaikka asiakas lopettaisikin käytön tai vähentäisi aktiivisten omavalvontasuunnitelmien määrää kesken laskutusjakson.</li>
                <li>Maksettava kokonaissumma määräytyy aktiivisten omavalvontasuunnitelmien mukaan.</li>
                <li>Laskutus tapahtuu kuukausittain etukäteen asiakkaan antamien laskutustietojen perusteella.</li>
              </ul>

              <Typography variant="h3">4. Sopimuksen kesto ja irtisanominen</Typography>

              <ul>
                <li>Sopimus Palvelun käytöstä on voimassa toistaiseksi.</li>
                <li>Asiakas voi irtisanoa sopimuksen poistamalla kaikki aktiiviset omavalvontasuunitelmat, jolloin maksuvelvollisuus loppuu laskutusjakson loppuun.</li>
                <li>Palveluntarjoajalla on oikeus irtisanoa sopimus välittömästi, jos asiakas rikkoo näitä Ehtoja tai käyttää Palvelua lainvastaisesti.</li>
              </ul>

              <Typography variant="h3">5. Vastuunrajoitus</Typography>

              <ul>
                <li>Palveluntarjoaja ei vastaa asiakkaan syöttämien tietojen oikeellisuudesta tai ajantasaisuudesta. Asiakas on yksin vastuussa kaikista Palveluun syöttämistään tiedoista ja niiden perusteella tehdyistä toimenpiteistä.</li>
                <li>Palveluntarjoaja ei vastaa mistään välittömistä tai välillisistä vahingoista, jotka johtuvat Palvelun käytöstä tai käytön estymisestä.</li>
              </ul>

              <Typography variant="h3">6. Henkilötietojen käsittely</Typography>

              <ul>
                <li>Palveluntarjoaja käsittelee asiakkaiden henkilötietoja tietosuojalainsäädännön mukaisesti. Tarkemmat tiedot henkilötietojen käsittelystä on esitetty Palveluntarjoajan tietosuojaselosteessa.</li>
              </ul>

              <Typography variant="h3">7. Muut ehdot</Typography>

              <ul>
                <li>Palveluntarjoajalla on oikeus muuttaa näitä Ehtoja ilmoittamalla siitä asiakkaalle sähköpostitse tai Palvelun kautta. Muutokset tulevat voimaan ilmoitettuna ajankohtana.</li>
                <li>Asiakkaan tulee hyväksyä muuttuneet Ehdot jatkaakseen Palvelun käyttöä. Jos asiakas ei hyväksy muutoksia, hänellä on oikeus irtisanoa sopimus kohdan 4.2 mukaisesti.</li>
                <li>Näihin Ehtoihin sovelletaan Suomen lakia. Mahdolliset erimielisyydet pyritään ensisijaisesti ratkaisemaan neuvotteluteitse. Mikäli sovintoon ei päästä, ratkaistaan riidat Helsingin käräjäoikeudessa.</li>
              </ul>
            </Stack>
          </Paper>
        </Stack>
      </DefaultContainer>
    </>
  )
}